export const replaceSpaces = (str) =>
  str
    // Remove <p>&nbsp;</p> and empty <div> or <p> tags containing only whitespace or &nbsp;
    .replace(/<\s*(div|p)[^>]*>\s*(?:&nbsp;|\s)*<\/\s*\1\s*>/g, '')
    .replace(/<\s*(div|p)[^>]*>\s*<\/\s*\1\s*>/g, '')
    .trim();

export const trimHTMLValue = (value) => {
  // Match all <div>...</div> and <p>...</p> blocks, including their content
  const paragraphs = value.match(/<\s*(div|p)[^>]*>[\s\S]*?<\/\s*\1\s*>/g) || [];

  const cleanedParagraphs = paragraphs
    .map((paragraph) => replaceSpaces(paragraph))
    .filter((paragraph) => paragraph !== '');

  return cleanedParagraphs.join('');
};

export const trimValueFromHTML = (value) => value.replace(/<\/?div[^>]*>/g, '').trim();

export const getFroalaText = (value) => {
  if (!value || !value?.length) {
    return '';
  }

  const trimedValueFromHTML = trimValueFromHTML(value);

  return trimedValueFromHTML.length ? trimedValueFromHTML : '';
};
