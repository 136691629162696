const SETTINGS_MENU_COLORS = {
  52: (theme) => theme.palette.blueLabels.bt_light_blue_hover, //customization
  95: (theme) => theme.palette.generic.sysaid_default, //administration
  112: (theme) => theme.palette.labels.bt_orange_hover, //tracking
  119: (theme) => theme.palette.labels.bt_purple_mid, //monitoring
};

export const BASIC_EDITION = 0;
export const FULL_EDITION = 2;
export const SETTINGS_MENU_ITEM_ID = 51;
export const TEMPLATES_MENU_ITEM_ID = 52;
export const TEMPLATES_SUB_MENU_LIST_ID = 53;
export const TEMPLATES_SUB_MENU_ITEM_ID = 54;
export const COPILOT_SUB_MENU_LIST_ID = 192;
export const AI_AGENTS_SUB_MENU_LIST_ID = 199;

export const AI_SUB_MENU_ITEM_IDS = [COPILOT_SUB_MENU_LIST_ID, AI_AGENTS_SUB_MENU_LIST_ID];

export const COPILOT_UPGRADE_LINK = 'https://www.sysaid.com/support/upgrade-account-request';

export { SETTINGS_MENU_COLORS };

export const COPILOT_MENU_CLASS = {
  UNAVAILABLE: 'copilot-menu-unavailable',
  DISABLE: 'copilot-menu-disable',
};

export const LIMITED_DATA_BY_COMPANY_ALL_FIELDS = 'limitedDataByCompany-allFields';

export const AI_BUILDER_MENU_ITEM_ID = 197;
export const AI_AGENTS_MONITORING_ITEM_ID = 200;
export const AI_AGENT_BUILDER_SETTINGS_ITEM_ID = 201;
export const AI_AGENTS_MANAGEMENT_ITEM_ID = 202;

export const AI_PLACEHOLDER_MENU_ITEM_IDS = [AI_AGENT_BUILDER_SETTINGS_ITEM_ID, AI_AGENTS_MANAGEMENT_ITEM_ID];
