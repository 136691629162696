import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { InlineFieldContainer } from 'common/components/controls/InlineField/InlineFieldContainer';
import { fieldValueExists } from 'common/utils/fieldUtils';
import { selectDirection } from 'store/globalSlice';
import { isRtl } from 'common/utils/utils';
import FieldName from '../fieldName';

export default function NumberField(props) {
  const {
    displayName,
    fieldValue,
    field,
    hasMoreDetails,
    url,
    urlParams,
    isTemplatePage,
    handleDirty,
    onFocus,
    handleSaveValue,
    required,
    updateFieldValidation,
    isNewField = false,
    subType,
    isAiEnabled,
    assistiveAI,
  } = props;
  const [text, setText] = useState('');
  const fieldName = field.fieldName;
  const direction = useSelector(selectDirection);
  const transformPosition = isRtl(direction) ? { transform: 'translateX(8px)' } : { transform: 'translateX(-8px)' };

  const checkAndUpdateValidationStatus = useCallback(
    (value) => {
      const isValid = !(!fieldValueExists({ value }) && required);
      if (updateFieldValidation) {
        updateFieldValidation(fieldName, required, isValid);
      }

      return isValid;
    },
    [required, fieldName, updateFieldValidation],
  );

  useEffect(() => {
    checkAndUpdateValidationStatus(text);
  }, [text, checkAndUpdateValidationStatus]);

  useEffect(() => {
    const initFieldValue = fieldValueExists(fieldValue) ? Number(fieldValue) : fieldValue;
    const initFieldDisplayName =
      typeof initFieldValue === 'object' && fieldValue !== null ? initFieldValue.templateDisplayName : initFieldValue;
    setText(initFieldDisplayName || initFieldDisplayName === 0 ? initFieldDisplayName : '');
  }, [fieldValue]);

  return (
    <>
      <FieldName
        url={url}
        name={displayName}
        required={required}
        urlParams={urlParams}
        fieldId={field?.fieldId}
        hasMoreDetails={hasMoreDetails}
        isTemplatePage={isTemplatePage}
        hintText={field?.hint && field?.hintText}
      />
      <div style={transformPosition}>
        <InlineFieldContainer
          isAiEnabled={isAiEnabled}
          assistiveAI={assistiveAI}
          fullwidth
          text={text}
          isNewField={isNewField}
          type="number"
          subType={subType}
          onFocus={onFocus}
          required={required}
          handleDirty={handleDirty}
          fieldName={field?.fieldName}
          isTemplatePage={isTemplatePage}
          handleSaveValue={handleSaveValue}
        />
      </div>
    </>
  );
}
