import { memo, useCallback, useMemo, useRef } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSelector, useDispatch } from 'react-redux';
import { displayUsersArray, PlaceholderValues } from 'common/utils/constants';
import { ConditionalWrapper, toDisplayUser } from 'common/utils/utils';
import { useSortable } from '@dnd-kit/sortable';
import DueDateField from 'common/components/dueDate';
import { useFieldAttributes } from 'remote-state/ticketServiceHooks';
import { useApplicationData, useRbTexts } from 'remote-state/applicationHooks';
import { getIsExistingSr } from 'common/utils/srUtils';
import { getFieldAttributesMap, isRequiredField, getFieldValue } from 'common/utils/fieldUtils';
import { ReactComponent as DragIcon } from 'images/icons/gh_dragg.svg';
import Links from 'common/components/LinksComponent';
import User from 'common/components/user';
import { selectActiveComponent } from 'features/templateBuilder/activeComponentSlice';
import { updateFieldValidationStatus } from 'store/fieldValidationsSlice';
import useGetFieldPermissions from 'common/utils/hooks/useGetFieldPermissions';
import useShowNoEditPermissionPopup from 'common/utils/hooks/useShowNoEditPermissionPopup';
import ReadOnlyTextField from '../readOnlyTextField';
import FieldSearchableDropdown from '../fieldSearchableDropdown';
import RichTextField from '../richTextField';
import DatepickerField from '../datepickerField';
import NumberField from '../numberField';
import TextField from '../textField';
import BooleanField from '../booleanField';
import { RICH_TEXT_FIELD_IDS, SR_PANEL_CONSTANTS } from '../../consts';
import { StyledContainer, StyledGrid, FieldComponentStyledContainer } from '../style';
import useTexts from '../useTexts';
import { IntegrationTypes } from '../../../IntegrationSection/IntegrationSection.consts';
import { QUERIES_KEYS } from '../../../../constant';
import { useGetAIConfigData } from '../../../../remote-state/aiConfigDataHook';

const FieldComponent = memo((props) => {
  const {
    field,
    fields,
    onFieldChange,
    handleFieldFocus,
    handleDirtyFields,
    sr,
    isTemplatePage,
    hasEditPermissions = true,
    isTicketLocked,
    onMouseEnter,
    onMouseLeave,
    isFloatingTicketPanel,
    getTicketAuditLogs,
  } = props;
  const fieldRef = useRef(null);
  const fieldAttributes = useFieldAttributes(fields, false, sr?.id);
  const getPermissionsRules = useGetFieldPermissions(field);
  const isExistingSR = getIsExistingSr(sr?.id);
  const dispatch = useDispatch();
  const texts = useTexts();
  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  const { data: assistiveAI } = useGetAIConfigData(QUERIES_KEYS.ASSISTIVE_AI);
  const { showNoPermissionPopup } = useShowNoEditPermissionPopup();

  const srStatusValue = sr?.status;
  const required = isRequiredField(srStatusValue, field?.required, field?.requiredStatuses);

  const updateFieldValidation = useCallback(
    (fieldName, required, isValid) => {
      const srId = sr?.id || 'new';
      if (typeof fieldName !== 'undefined' && fieldName !== null && !isTemplatePage) {
        dispatch(updateFieldValidationStatus({ srId, fieldName, required, isValid }));
      }
    },
    [sr?.id, dispatch, isTemplatePage],
  );

  const fieldAttributesMap = useMemo(() => getFieldAttributesMap(fieldAttributes, fields), [fieldAttributes, fields]);

  const fieldValue = useMemo(() => getFieldValue(field, sr), [field, sr]);
  const rbTexts = useRbTexts();

  const displayName = useMemo(() => {
    if (!field?.hideFieldCaption) {
      const displayName = fieldAttributesMap[field?.fieldName]?.displayName;
      return rbTexts[displayName] || field.templateFieldAttributes?.displayName;
    }
    return '';
  }, [
    field?.fieldName,
    field?.hideFieldCaption,
    field.templateFieldAttributes?.displayName,
    fieldAttributesMap,
    rbTexts,
  ]);

  const values = useMemo(
    () => fieldAttributesMap[field.fieldName]?.values || [],
    [fieldAttributesMap, field.fieldName],
  );

  const handleFieldChange = useCallback(
    (newValue) => {
      onFieldChange({ [field.fieldName]: newValue });
    },
    [onFieldChange, field.fieldName],
  );

  const handleLinksChange = useCallback(
    async (links) => {
      onFieldChange({ [field.fieldName]: JSON.stringify(links) });
    },
    [onFieldChange, field.fieldName],
  );

  const handleInlineFieldChange = useCallback(
    (newObject) => {
      onFieldChange(newObject);
    },
    [onFieldChange],
  );

  const isNoEditPermission = field?.readOnly || !hasEditPermissions || getPermissionsRules?.disabled;
  const isDisabled = isNoEditPermission || field?.templateFieldAttributes?.alwaysReadOnly || isTicketLocked;
  // Temporary solution for displaying due date field.
  // TODO: Fix the rendering condition of the DueDate component, after BE will add a new field.
  let ticketTemplateFieldTypeId = field?.templateFieldAttributes?.ticketTemplateFieldType?.id;
  if (field?.fieldName === 'dueDate' && (ticketTemplateFieldTypeId === 4 || ticketTemplateFieldTypeId === 10)) {
    ticketTemplateFieldTypeId = 5;
  }

  switch (ticketTemplateFieldTypeId) {
    case SR_PANEL_CONSTANTS.SELECT:
    case SR_PANEL_CONSTANTS.MULTI_SELECT:
      return (
        <FieldSearchableDropdown
          field={field}
          srType={sr?.srType}
          fieldValue={fieldValue}
          isNewField={!isExistingSR}
          values={values}
          displayName={displayName}
          required={required}
          disabled={isDisabled}
          sortBy={fieldAttributesMap[field.fieldName]?.sortBy}
          url={field.templateFieldAttributes.url}
          urlParams={field.templateFieldAttributes.urlParams}
          hasMoreDetails={field.templateFieldAttributes.isMoreDetails}
          isTemplatePage={isTemplatePage}
          isFloatingTicketPanel={isFloatingTicketPanel}
          handleDirty={handleDirtyFields}
          isMultiple={field?.templateFieldAttributes?.ticketTemplateFieldType?.id === SR_PANEL_CONSTANTS.MULTI_SELECT}
          onChange={handleFieldChange}
          onFocus={handleFieldFocus}
          updateFieldValidation={updateFieldValidation}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          {...getPermissionsRules}
        />
      );

    case SR_PANEL_CONSTANTS.TIME_REMAINING:
      return (
        <DueDateField
          statusId={!isTemplatePage && sr?.status}
          disablePortal={false}
          fieldName={field.fieldName}
          displayName={displayName}
          required={required}
          startDate={sr?.insertTime}
          endDate={sr?.dueDate || field.defaultValue}
          disabled={isDisabled || isTemplatePage}
          field={field}
          hasMoreDetails={field.templateFieldAttributes.isMoreDetails}
          url={field.templateFieldAttributes.url}
          urlParams={field.templateFieldAttributes.urlParams}
          isTemplatePage={isTemplatePage}
          srType={sr?.srType}
          onSaveDate={handleFieldChange}
          onClick={handleFieldFocus}
          handleDirty={handleDirtyFields}
          updateFieldValidation={updateFieldValidation}
          emptyValueTooltip={texts.mandatoryFieldError}
          srStatusValue={srStatusValue}
          {...getPermissionsRules}
        />
      );
    case SR_PANEL_CONSTANTS.TEXT:
    case SR_PANEL_CONSTANTS.LONG_TEXT:
      if (RICH_TEXT_FIELD_IDS.includes(field.fieldId)) {
        return (
          <RichTextField
            field={field}
            sourceId={sr?.id || 0}
            fieldRef={fieldRef}
            isTemplatePage={isTemplatePage}
            onFocus={handleFieldFocus}
            handleSaveValue={handleInlineFieldChange}
            fieldValue={fieldValue}
            isReadOnly={isDisabled}
            displayName={displayName}
            required={required}
            hasMoreDetails={field.templateFieldAttributes.isMoreDetails}
            url={field.templateFieldAttributes.url}
            urlParams={field.templateFieldAttributes.urlParams}
            dataTestId="sr-description-editor"
            handleDirty={handleDirtyFields}
            onClickAway={getTicketAuditLogs}
            updateFieldValidation={updateFieldValidation}
            isNewField={!isExistingSR}
          />
        );
      }
      return !isDisabled ? (
        <TextField
          field={field}
          fieldValue={fieldValue}
          displayName={displayName}
          required={required}
          sr={sr}
          source="ticket"
          fullwidth
          hasMoreDetails={field.templateFieldAttributes.isMoreDetails}
          url={field.templateFieldAttributes.url}
          urlParams={field.templateFieldAttributes.urlParams}
          isTemplatePage={isTemplatePage}
          handleSaveValue={handleInlineFieldChange}
          handleDirty={handleDirtyFields}
          onFocus={handleFieldFocus}
          updateFieldValidation={updateFieldValidation}
          isNewField={!isExistingSR}
          {...getPermissionsRules}
        />
      ) : (
        <ReadOnlyTextField
          field={field}
          displayName={displayName}
          required={required}
          fieldValue={fieldValue}
          source="ticket"
          fullwidth
          hasMoreDetails={field.templateFieldAttributes.isMoreDetails}
          url={field.templateFieldAttributes.url}
          urlParams={field.templateFieldAttributes.urlParams}
          isTemplatePage={isTemplatePage}
          updateFieldValidation={updateFieldValidation}
          isNoEditPermission={isNoEditPermission}
          {...(isNoEditPermission ? { onClick: showNoPermissionPopup } : {})}
          {...getPermissionsRules}
        />
      );

    case SR_PANEL_CONSTANTS.NUMBER:
      return !isDisabled ? (
        <NumberField
          field={field}
          fieldValue={fieldValue}
          displayName={displayName}
          required={required}
          sr={sr}
          fieldName={field.fieldName}
          hasMoreDetails={field.templateFieldAttributes.isMoreDetails}
          url={field.templateFieldAttributes.url}
          urlParams={field.templateFieldAttributes.urlParams}
          isTemplatePage={isTemplatePage}
          onFocus={handleFieldFocus}
          handleSaveValue={handleInlineFieldChange}
          handleDirty={handleDirtyFields}
          updateFieldValidation={updateFieldValidation}
          isNewField={!isExistingSR}
          subType={field?.templateFieldAttributes?.ticketTemplateFieldType?.subType}
          isAiEnabled={isAiEnabled}
          assistiveAI={assistiveAI}
        />
      ) : (
        <ReadOnlyTextField
          field={field}
          displayName={displayName}
          required={required}
          fieldValue={fieldValue}
          source="ticket"
          fullwidth
          hasMoreDetails={field.templateFieldAttributes.isMoreDetails}
          url={field.templateFieldAttributes.url}
          urlParams={field.templateFieldAttributes.urlParams}
          isTemplatePage={isTemplatePage}
          updateFieldValidation={updateFieldValidation}
        />
      );
    case SR_PANEL_CONSTANTS.DATE:
    case SR_PANEL_CONSTANTS.DATE_TIME:
      return (
        <DatepickerField
          sr={sr}
          field={field}
          isNewField={!isExistingSR}
          fieldValue={fieldValue}
          fieldName={field.fieldName}
          required={required}
          displayName={displayName}
          disabled={isDisabled}
          hasMoreDetails={field.templateFieldAttributes.isMoreDetails}
          url={field.templateFieldAttributes.url}
          urlParams={field.templateFieldAttributes.urlParams}
          isTemplatePage={isTemplatePage}
          placeholder={
            isDisabled && ticketTemplateFieldTypeId === SR_PANEL_CONSTANTS.DATE_TIME && PlaceholderValues.NotAvailable
          }
          onFocus={handleFieldFocus}
          onChange={handleFieldChange}
          updateFieldValidation={updateFieldValidation}
          emptyValueTooltip={texts.mandatoryFieldError}
        />
      );
    case SR_PANEL_CONSTANTS.LINK:
    case SR_PANEL_CONSTANTS.SPECIAL_LINK:
      return (
        <Links
          field={field}
          fieldValue={fieldValue}
          displayName={displayName}
          required={required}
          disabled={isDisabled}
          hasMoreDetails={field.templateFieldAttributes.isMoreDetails}
          url={field.templateFieldAttributes.url}
          urlParams={field.templateFieldAttributes.urlParams}
          isTemplatePage={isTemplatePage}
          onLinksChange={handleLinksChange}
          onFocus={handleFieldFocus}
          handleDirty={handleDirtyFields}
          updateFieldValidation={updateFieldValidation}
        />
      );
    case SR_PANEL_CONSTANTS.BOOLEAN:
      return (
        <BooleanField
          field={field}
          fieldValue={fieldValue}
          isNewField={!isExistingSR}
          displayName={displayName}
          required={required}
          disabled={isDisabled}
          hasMoreDetails={field.templateFieldAttributes.isMoreDetails}
          isTemplatePage={isTemplatePage}
          onChange={handleFieldChange}
          onFocus={handleFieldFocus}
          texts={texts}
          {...getPermissionsRules}
        />
      );
    case 'placeholder':
      return <div className="form-field-placeholder">{displayName}</div>;
    default:
      return null;
  }
});
const FieldComponentWrapper = (props) => {
  const { field, sr, isTemplatePage, isJiraIntegrationSection } = props;
  const { componentId } = useSelector(selectActiveComponent);

  const isDraggable = !(field.fieldName.includes(IntegrationTypes.JIRA.toLowerCase()) && isJiraIntegrationSection);

  const fieldValue = useMemo(() => getFieldValue(field, sr), [field, sr]);

  const { attributes, setNodeRef, setActivatorNodeRef, listeners, transform, transition } = useSortable({
    id: field.fieldName,
  });
  const getFieldWidth = (position) => (position === 'STRETCH' ? '100%' : '49%');
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    flexBasis: getFieldWidth(field.position),
  };

  return !isTemplatePage ? (
    <StyledGrid fieldWidth={getFieldWidth(field.position)}>
      <ConditionalWrapper
        condition={toDisplayUser(displayUsersArray, field.fieldName)}
        wrapper={(children) => <User userName={fieldValue?.userName || fieldValue}>{children}</User>}
      >
        <FieldComponent {...props} />
      </ConditionalWrapper>
    </StyledGrid>
  ) : (
    <StyledContainer
      isDraggable={isDraggable}
      isLinkField={field.fieldName === 'link'}
      isTemplatePage={isTemplatePage}
      isActiveField={componentId === field?.fieldId}
      className="field-container"
      id={field.fieldName}
      style={style}
      ref={setNodeRef}
      {...attributes}
    >
      {isDraggable && <DragIcon className="drag-icon" ref={setActivatorNodeRef} {...listeners} />}
      <FieldComponentStyledContainer className="field-wrapper">
        <FieldComponent {...props} />
      </FieldComponentStyledContainer>
    </StyledContainer>
  );
};
export default memo(FieldComponentWrapper);
