import { useEffect, useState } from 'react';
import { getIsExistingSr } from 'common/utils/srUtils';
import theme from 'features/theme/themes/lightTheme';
import { SECTIONS_CONSTANTS } from './constants';
import Section from './section';
import { StyledSectionsWrapper } from './style';

export default function Sections(props) {
  const { sr, template, handleDirtyFields, handleSaveSR, hasEditPermissions, isFloatingTicketPanel, isArchived, onGetJiraIssueKey } = props;
  const [sectionsCollapsed, setSectionsCollapsed] = useState();
  const srId = sr.id;
  const isExistingSR = getIsExistingSr(srId);
  const sections = template?.sections?.sort((a, b) => a.order - b.order);
  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: isFloatingTicketPanel ? '24px 0px 12px' : '0px',
    },
    divider: {
      width: 'calc(100% - 22px)',
      margin: '30px 0 40px 24px',
      backgroundColor: theme?.palette?.grey?.divider_grey_v1,
      border: 'none',
      height: '1px'
    }
  }

  const getCollapsedSections = () => {
    let storedSectionData = sessionStorage.getItem(SECTIONS_CONSTANTS.SECTIONS_COLLAPSED_SESSION_KEY);
    storedSectionData = JSON.parse(storedSectionData);
    if (!storedSectionData) {
      storedSectionData = {};
    }
    return storedSectionData;
  };

  const setSectionsCollapsedInSession = (key, value) => {
    const sectionsStatus = getCollapsedSections();
    sectionsStatus[key] = value;
    setSectionsCollapsed(sectionsStatus);
    sessionStorage.setItem(SECTIONS_CONSTANTS.SECTIONS_COLLAPSED_SESSION_KEY, JSON.stringify(sectionsStatus));
  };

  useEffect(() => {
    if (isExistingSR) {
      const sectionsStatus = getCollapsedSections();
      if (sectionsStatus) {
        setSectionsCollapsed(sectionsStatus);
      }
    }
  }, [isExistingSR]);

  const handleExpansion = (section, value) => setSectionsCollapsedInSession(`${srId}_${section.order}`, value);

  return (
    <StyledSectionsWrapper isArchived={isArchived}>
      <div
        style={styles.wrapper}
        data-cy="sections-wrapper"
      >
        {sections?.map((section, index) => (
          <div key={section.sectionId} style={{ paddingInlineEnd: '40px' }}>
            <Section
              section={section}
              srId={srId}
              handleDirtyFields={handleDirtyFields}
              handleSaveSR={handleSaveSR}
              sr={sr}
              hasEditPermissions={hasEditPermissions}
              isFloatingTicketPanel={isFloatingTicketPanel}
              onExpansion={isExistingSR ? handleExpansion : undefined}
              initExpanded={(sectionsCollapsed && sectionsCollapsed[`${srId}_${section.order}`]) ?? true}
              onGetJiraIssueKey={onGetJiraIssueKey}
            />
            {sections.length !== (index + 1) && <hr style={styles.divider} />}
          </div>
        ))}
      </div>
    </StyledSectionsWrapper>
  );
}
