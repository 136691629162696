import { useCallback, useMemo } from 'react';
import Tooltip from 'common/components/tooltip';
import { DropdownSelect, useDropdownControl } from 'common/components/dropdownSelect';
import { fieldValueExists } from 'common/utils/fieldUtils';
import { PlaceholderValues } from 'common/utils/constants';
import { ReactComponent as ArrowDownIcon } from 'images/icons/arrowdown.svg';
import { ReactComponent as WarningIcon } from 'images/icons/warning_icon.svg';
import FieldName from '../fieldName';
import { TicketBodySelectButton } from './style';

export default function BooleanField(props) {
  const {
    displayName,
    fieldValue,
    field,
    disabled,
    url,
    urlParams,
    onFocus,
    onChange,
    required,
    tooltipText,
    isNewField = false,
    isTableView = false,
    hasMoreDetails = false,
    isTemplatePage = false,
    texts,
  } = props;
  const { mandatoryFieldError, yesText, noText } = texts;
  const fieldName = field?.fieldName;
  const { anchor, handleOpen, handleClose } = useDropdownControl(disabled, onFocus);

  const customValues = useMemo(
    () => [
      {
        id: 1,
        valueKey: 1,
        valueCaption: yesText,
        valueForFilter: yesText,
      },
      {
        id: 2,
        valueKey: 2,
        valueCaption: noText,
        valueForFilter: noText,
      },
    ],
    [yesText, noText],
  );

  const isEmptyField = !fieldValueExists({
    value: fieldValue,
    fieldTypeId: field?.templateFieldAttributes?.ticketTemplateFieldType?.id,
  });

  const getTooltipText = useMemo(() => {
    let text = '';
    if (customValues.length && !isEmptyField) {
      text = fieldValue ? yesText : noText;
    } else {
      text = PlaceholderValues.NotAvailable;
    }
    return text;
  }, [customValues.length, fieldValue, isEmptyField, noText, yesText]);

  const onSelectChange = useCallback(
    async (selectedValue) => {
      onChange(selectedValue);
      handleClose();
    },
    [onChange, handleClose],
  );

  const checkIsError = useCallback(() => {
    const isInvalid = isEmptyField && required;
    const newFieldError = isNewField && isInvalid;
    const existingFieldError = !isNewField && isInvalid;
    return newFieldError || existingFieldError;
  }, [isEmptyField, isNewField, required]);

  const showWarningIcon = useMemo(() => {
    const isError = checkIsError();
    return (isError && !isNewField) || (isNewField && isEmptyField && isError);
  }, [checkIsError, isEmptyField, isNewField]);

  const MenuProps = useMemo(() => ({ 'data-testid': `boolean-field-${fieldName}-menu` }), [fieldName]);

  const selection = useMemo(() => ({ values: [fieldValue] }), [fieldValue]);

  return (
    <>
      {!isTableView && (
        <FieldName
          url={url}
          name={displayName}
          required={required}
          urlParams={urlParams}
          fieldId={field?.fieldId}
          hasMoreDetails={hasMoreDetails}
          isTemplatePage={isTemplatePage}
          hintText={field?.hint && field?.hintText}
          texts={texts}
        />
      )}
      <TicketBodySelectButton
        hasValues={!!customValues.length}
        onClick={handleOpen}
        anchor={!!anchor}
        disabled={disabled}
        className={`boolean-field ${checkIsError() && 'with-error'}`}
        isNewField={isNewField}
        isError={checkIsError()}
        isEmptyField={isEmptyField}
        isTemplatePage={isTemplatePage}
        isTableView={isTableView}
        data-testid={`boolean-field-${field.fieldName}`}
      >
        {showWarningIcon ? (
          <div className="warning-icon">
            <Tooltip
              isError
              title={mandatoryFieldError}
              disableHoverListener={!required || !mandatoryFieldError}
              placement="top"
              text={<WarningIcon />}
            />
          </div>
        ) : null}
        <Tooltip
          style={{ fontSize: '0.875rem' }}
          title={tooltipText}
          forceShowOnHover={tooltipText != null}
          isTruncatedText
          text={getTooltipText()}
          placement="top"
        />
        {!isTableView && !disabled && <ArrowDownIcon data-testid="arrow-down-icon" />}
      </TicketBodySelectButton>
      <DropdownSelect
        fieldName={field.fieldName}
        selection={selection}
        options={customValues}
        disabled={disabled}
        handleChange={onSelectChange}
        handleClose={handleClose}
        anchor={anchor}
        handleOpen={handleOpen}
        isActionsHidden
        isHeaderHidden
        MenuProps={MenuProps}
      />
    </>
  );
}
